<div class="input-address-container flex flex-1 gap-4 flex-wrap">
  <div
    class="flex-1"
    [class]="childForm.key"
    *ngFor="let childForm of addressContainer; let i = index"
  >
    <ng-container *ngIf="childForm.type === 'select'">
      <ptg-select
        *ngIf="childForm.formControl"
        [controlField]="childForm.formControl"
        [placeholder]="childForm.label"
        [listData]="childForm.lstOption || []"
        [isOptionObj]="true"
        [hasNoneValue]="!addressProperty?.isRequired || !childForm.required"
        [isRequired]="isAddressHistory ? true : addressProperty?.isRequired && childForm.required"
      ></ptg-select>
    </ng-container>
    <ng-container *ngIf="childForm.type === 'text'">
      <ng-container *ngIf="childForm.key === 'zipCode'">
        <ptg-textbox
          *ngIf="selectedCountryCode === 'USA' && childForm.formControl"
          [controlField]="childForm.formControl"
          [placeholder]="childForm.label"
          [hasLabel]="true"
          [maxLength]="childForm.maxLength"
          [isRequired]="isAddressHistory ? true : addressProperty?.isRequired && childForm.required"
          inputType="ZipCode"
        ></ptg-textbox>
        <ptg-textbox
          *ngIf="(selectedCountryCode === 'CA' || selectedCountryCode === 'CAN') && childForm.formControl"
          [controlField]="childForm.formControl"
          [placeholder]="childForm.label"
          [hasLabel]="true"
          [maxLength]="childForm.maxLength"
        ></ptg-textbox>
        <ptg-textbox
          *ngIf="!['USA', 'CA', 'CAN'].includes(selectedCountryCode) && childForm.formControl"
          [controlField]="childForm.formControl"
          [placeholder]="childForm.label"
          [hasLabel]="true"
        ></ptg-textbox>
      </ng-container>
      <ng-container *ngIf="childForm.key === 'state'">
        <ptg-textbox
          *ngIf="childForm.formControl"
          [controlField]="childForm.formControl"
          [placeholder]="childForm.label"
          [hasLabel]="true"
        ></ptg-textbox>
      </ng-container>
      <ng-container *ngIf="childForm.key !== 'zipCode' && childForm.key !== 'state'">
        <ptg-textbox
          *ngIf="childForm.formControl"
          [controlField]="childForm.formControl"
          [placeholder]="childForm.label"
          [hasLabel]="true"
          [maxLength]="childForm.maxLength"
          [isRequired]="isAddressHistory ? childForm.key !== 'street2' : addressProperty?.isRequired && childForm.required"
        ></ptg-textbox>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="childForm.type === 'date' && !removeEffectRange">
      <ptg-datepicker
        *ngIf="childForm.formControl"
        [controlField]="childForm.formControl"
        [placeholder]="childForm.label"
        [isRequired]="isAddressHistory ? childForm.key !== 'effectTo' : childForm.required"
        [minDate]="MY_DATE.minDate"
        [maxDate]="MY_DATE.maxDate"
        [isValidation]="true"
        customError="fromToValidation"
        [errorAsync]="addressProperty?.messageError"
        (changeValue)="dateEffectiveChange()"
      ></ptg-datepicker>
    </ng-container>
  </div>
</div>
